import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AppLayoutModule} from "./layout/app.layout.module";
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {ChartModule} from 'primeng/chart';
import {VolumePriceSurfaceComponent} from './components/charting/volume-price-surface/volume-price-surface.component';
import {ToolbarModule} from "primeng/toolbar";
import {SplitButtonModule} from "primeng/splitbutton";
import {ProgressBarModule} from 'primeng/progressbar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, provideHttpClient, withInterceptors} from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthComponent } from './components/auth/auth.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { ProbabilitySurfaceComponent } from './components/charting/probability-surface/probability-surface.component';
import {schwabAuthInterceptorInterceptor} from "./shared/interceptors/schwab-auth-interceptor.interceptor";
import {CalendarModule} from "primeng/calendar";
import {CheckboxModule} from "primeng/checkbox";
import {DropdownModule} from "primeng/dropdown";
import { PlotlyModule } from 'angular-plotly.js';
import * as PlotlyJS from 'plotly.js-dist-min';
import {ToastModule} from "primeng/toast";
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';


// Set the Plotly.js library for the PlotlyModule
PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
	declarations: [
		AppComponent,
		DashboardComponent,
		VolumePriceSurfaceComponent,
  AuthComponent,
  ProbabilitySurfaceComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		AppLayoutModule,
		ChartModule,
		ToolbarModule,
		SplitButtonModule,
		ProgressBarModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		NgxJsonViewerModule,
		CalendarModule,
		CheckboxModule,
		DropdownModule,
		PlotlyModule,
		ToastModule,
		ButtonModule,
		AccordionModule,
	],
	providers: [
		MessageService,
		provideHttpClient(
			withInterceptors([schwabAuthInterceptorInterceptor]) // Register the functional interceptor
		),
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
