<p-toast position="bottom-right" key="br"/>
<p-accordion [multiple]="true" [activeIndex]="[1,2]">
	<p-accordionTab header="About">
	</p-accordionTab>
	<p-accordionTab header="Filters">
		<!--<div class="card">-->
		<p-toolbar>
			<form [formGroup]="priceHistoryForm" class="p-fluid p-formgrid p-grid">
				<!-- Symbol -->
				<div class="p-field p-col-12 p-md-6">
					<label for="symbol">Symbol <span class="required">*</span></label>
					<input
						pInputText
						id="symbol"
						formControlName="symbol"
						placeholder="Enter symbol (e.g., AAPL)"
						[class.p-invalid]="priceHistoryForm.get('symbol')?.invalid && priceHistoryForm.get('symbol')?.touched"
					/>
					<small
						*ngIf="priceHistoryForm.get('symbol')?.invalid && priceHistoryForm.get('symbol')?.touched"
						class="p-error">
						Symbol is required.
					</small>
				</div>

				<!-- Period Type -->
				<div class="p-field p-col-12 p-md-6">
					<label for="periodType">Period Type</label>
					<p-dropdown
						id="periodType"
						formControlName="periodType"
						[options]="periodTypes"
						placeholder="Select Period Type"
					></p-dropdown>
				</div>

				<!-- Period -->
				<div class="p-field p-col-12 p-md-6">
					<label for="period">Period</label>
					<input
						pInputText
						id="period"
						type="number"
						formControlName="period"
						placeholder="Enter Period"
					/>
				</div>

				<!-- Frequency Type -->
				<div class="p-field p-col-12 p-md-6">
					<label for="frequencyType">Frequency Type</label>
					<p-dropdown
						id="frequencyType"
						formControlName="frequencyType"
						[options]="frequencyTypes"
						placeholder="Select Frequency Type"
					></p-dropdown>
				</div>

				<!-- Frequency -->
				<div class="p-field p-col-12 p-md-6">
					<label for="frequency">Frequency</label>
					<input
						pInputText
						id="frequency"
						type="number"
						formControlName="frequency"
						placeholder="Enter Frequency"
					/>
				</div>

				<!-- Start Date -->
				<div class="p-field p-col-12 p-md-6">
					<label for="startDate">Start Date</label>
					<p-calendar
						id="startDate"
						formControlName="startDate"
						showIcon="true"
						placeholder="Select Start Date"
					></p-calendar>
				</div>

				<!-- End Date -->
				<div class="p-field p-col-12 p-md-6">
					<label for="endDate">End Date</label>
					<p-calendar
						id="endDate"
						formControlName="endDate"
						showIcon="true"
						placeholder="Select End Date"
					></p-calendar>
				</div>

				<!-- CheckBoxes -->
				<div class="p-field-checkbox p-col-12 p-md-6">
					<!-- Need Extended Hours Data -->
					<label for="needExtendedHoursData">Need Extended Hours Data</label>
					<p-checkbox
						id="needExtendedHoursData"
						formControlName="needExtendedHoursData"
						inputId="needExtendedHoursData"
						binary="true"
					></p-checkbox>
					<!-- Need Previous Close -->
					<label for="needPreviousClose">Need Previous Close</label>
					<p-checkbox
						id="needPreviousClose"
						formControlName="needPreviousClose"
						inputId="needPreviousClose"
						binary="true"
					></p-checkbox>
				</div>

				<!-- Form Actions -->
				<div class="p-field-buttons">
					<button pButton icon="pi pi-search" label="Submit" (click)="submitForm()"></button>
					<button pButton icon="pi pi-filter-slash" label="Reset" (click)="resetForm()"></button>
				</div>
			</form>
		</p-toolbar>
		<!--	<p-progressBar [value]="progressBarVal"></p-progressBar>-->
		<!--</div>-->
	</p-accordionTab>
	<p-accordionTab header="Graphs">
		<!--	<div class="card">-->
		<div *ngIf="showRawDataBool">Raw Data:
			<!--{{testData | json}}-->
			<ngx-json-viewer [json]="priceHistoryData" [expanded]="false"></ngx-json-viewer>
		</div>
		<form [formGroup]="dataDisplayForm" class="p-fluid p-formgrid p-grid">
			<!-- Bin Size -->
			<div class="p-field p-col-12 p-md-6">
				<label for="binSize">Bin Size</label>
				<p-dropdown
					id="binSize"
					formControlName="binSize"
					[options]="binSizes"
					placeholder="Select Bin Size"
				></p-dropdown>
			</div>
			<div class="p-field p-col-12 p-md-6">
				<label for="testLevel">Test Level</label>
				<p-dropdown
					id="testLevel"
					formControlName="testLevel"
					[options]="[-10, -9, -8, -7 ,-6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4 ,5, 6, 7, 8, 9, 10]"
					placeholder="Select Test Level"
				></p-dropdown>
			</div>
			<!-- Frequency -->
			<div class="p-field p-col-12 p-md-6">
				<label for="splitPointPercentage">Split Point Percentage</label>
				<input
					pInputText
					id="splitPointPercentage"
					type="number"
					formControlName="splitPointPercentage"
					placeholder="Enter Split Point Percentage"
				/>
			</div>
			<!-- CheckBoxes -->
			<div class="p-field-checkbox p-col-12 p-md-6">
				<!-- Show Raw Data -->
				<label for="showRawData">Show Raw Data: </label>
				<p-checkbox
					id="showRawData"
					formControlName="showRawData"
					inputId="showRawData"
					binary="true"
				></p-checkbox>
			</div>
		</form>
		<div>
			Total Probability: {{ totalProb }}
			Split at {{ dataDisplayForm.get('testLevel')?.value }} : {{ splitProb | json }}
			SplitPoint: {{ splitPoint }}
		</div>
		<div class="container">
			<plotly-plot [data]="graphData" [layout]="graphLayout"></plotly-plot>
		</div>
		<!--	</div>-->
	</p-accordionTab>
</p-accordion>

